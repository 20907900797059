<template>
    <div class="active modal-register">
        <div class="container-modal form-modal">
            <div class="container-holder">
                <div class="header-modal">
                    <div class="icon"><icon-check-bold /></div>
                    <div class="title" v-html="$t('reset_password_success.title')"></div>
				    <div class="description">{{ $t('reset_password_success.description') }}</div>
                </div>

                <div class="submit-footer">
                    <div class="submit-form">
                        <button id="buttonSubmit" class="button-submit" @click="redirect()">
                            <div class="text">{{$t('reset_password_success.button-action')}}</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconCheckBold from '../Icons/CheckBold'

export default {
	components: {
        IconCheckBold
    },
	methods: {
        redirect() {
            this.$router.push("/login");
        }
	}
}
</script>